body {
  background-color: #f6f6f4;
}

.link-purple {
  color: #400180;
}
.link-purple:hover {
  color: rgb(130, 86, 213);
  text-decoration: none;
}

.link-orange {
  color: #f9993f;
}
.link-orange:hover {
  color: #f93f9f;
  
  text-decoration: none;
}

.card-header-purple {
  background-color: #400180;
  color: white;
}
.card-header-orange{
  background-color: #f9993f;
  color: white;
}

/* Custom buttons */

.btn-purple {
  background-color: #400180;
  color: white;
}
.btn-purple:hover {
  background-color: #67309d;
  color: white;
}

.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}